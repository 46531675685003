const _temp0 = require("../locales/ca.json");
const _temp1 = require("../locales/en.json");
const _temp2 = require("../locales/es.json");
const _temp3 = require("../locales/fr.json");
const _temp4 = require("../locales/ru.json");
module.exports = {
  "ca": _temp0,
  "en": _temp1,
  "es": _temp2,
  "fr": _temp3,
  "ru": _temp4
}