{
  "days": {
    "friday": "Divendres",
    "monday": "Dilluns",
    "saturday": "Dissabte",
    "sunday": "Diumenge",
    "thursday": "Dijous",
    "tuesday": "Dimarts",
    "wednesday": "Dimecres"
  },
  "discover": {
    "explore": "Explora Ubicacions"
  },
  "explore_list": {
    "description": "Aquesta llista s'ha extret de {wikipage}.",
    "error": "Error mentre es carregava la llista.",
    "subtitle": "{city} - {nation}",
    "title": "Explora",
    "wikipage": "Pàgina de la wiki d'Etiquetatge Senzill per a Interiors"
  },
  "heatmap": {
    "hide": "Oculta el mapa de calor",
    "show": "Mostra el mapa de calor"
  },
  "opening_hours": {
    "state": {
      "closed": "tancat",
      "open": "obert"
    },
    "state_until_date": "{state} fins el {date}",
    "tomorrow": "demà",
    "url": "Visita la pàgina per veure els horaris d'obertura"
  },
  "poi": {
    "aerialway": {
      "station": "Estació"
    },
    "aeroway": {
      "gate": "Porta d'Embarcament"
    },
    "amenity": {
      "arts_centre": "Centre d'arts",
      "atm": "Caixer automàtic",
      "bank": "Banc",
      "bar": "Bar",
      "bbq": "Barbacoa",
      "bench": "Banc",
      "bicycle_parking": "Aparcament de bicicletes",
      "bicycle_rental": "Lloguer de bicicletes",
      "biergarten": "Biergarten",
      "bureau_de_change": "Canvi de divises",
      "bus_station": "Estació d'autobusos",
      "cafe": "Cafeteria",
      "car_rental": "Lloguer de cotxes",
      "cinema": "Cinema",
      "clinic": "Clínica",
      "college": "Universitat",
      "community_centre": "Centre comunitari",
      "courthouse": "Jutjat",
      "dentist": "Dentista",
      "doctors": "Metges",
      "embassy": "Ambaixada",
      "fast_food": "Menjar ràpid",
      "ferry_terminal": "Terminal de ferri",
      "fire_station": "Estació de bombers",
      "food_court": "Pati de restauració",
      "fuel": "Combustible",
      "grave_yard": "Cementiri",
      "hospital": "Hospital",
      "ice_cream": "Gelat",
      "kindergarten": "Escola bressol",
      "library": "Biblioteca",
      "marketplace": "Mercat",
      "motorcycle_parking": "Aparcament de motocicletes",
      "nightclub": "Discoteca",
      "nursing_home": "Residencia de gent gran",
      "parking": "Aparcament",
      "pharmacy": "Farmàcia",
      "photo_booth": "Cabina de fotos",
      "piano": "Piano",
      "place_of_worship": "Lloc de culte",
      "police": "Policia",
      "post_box": "Bústia de correus",
      "post_office": "Oficina de correus",
      "prison": "Pressó",
      "pub": "Bar",
      "public_building": "Edifici públic",
      "recycling": "Reciclatge",
      "restaurant": "Restaurant",
      "school": "Escola",
      "shelter": "Refugi",
      "swimming_pool": "Piscina",
      "taxi": "Taxi",
      "telephone": "Telèfon",
      "theatre": "Teatre",
      "ticket_validator": "Validador de bitllets",
      "toilets": "Lavabos",
      "townhall": "Ajuntament",
      "university": "Universitat",
      "vending_machine": "Màquina de vending",
      "veterinary": "Veterinari",
      "waste_basket": "Paperera"
    },
    "barrier": {
      "bollard": "Piló",
      "border_control": "Control fronterer",
      "cycle_barrier": "Barrera ciclable",
      "gate": "Porta",
      "lift_gate": "Porta elevadora",
      "sally_port": "Entrada segura",
      "stile": "Estil",
      "toll_booth": "Cabina de peatge"
    },
    "craft": {
      "brewery": "Cerveseria",
      "caterer": "Càtering",
      "confectionery": "Llaminadures",
      "dressmaker": "Modista",
      "electronics_repair": "Reparació d'electrònica",
      "shoemaker": "Sabater",
      "tailor": "Sastre"
    },
    "door": {
      "door": "Porta",
      "hinged": "Porta",
      "no": "Porta",
      "overhead": "Porta",
      "sliding": "Porta corredissa",
      "yes": "Porta"
    },
    "emergency": {
      "defibrillator": "Desfibril·lador",
      "fire_extinguisher": "Extintor",
      "phone": "Telèfon d'emergència"
    },
    "entrance": {
      "building": "Entrada",
      "emergency": "Sortida d'emergència",
      "exit": "Sortida",
      "home": "Entrada",
      "main": "Entrada",
      "service": "Servei",
      "shop": "Entrada",
      "staircase": "Entrada",
      "yes": "Entrada"
    },
    "exhibit": {
      "artwork": "Obra d'art",
      "history": "Història"
    },
    "highway": {
      "elevator": "Ascensor"
    },
    "landuse": {
      "basin": "Conca",
      "brownfield": "Solar",
      "cemetery": "Cementiri",
      "reservoir": "Dipòsit",
      "winter_sports": "Esports d'hivern"
    },
    "leisure": {
      "dog_park": "Parc de gossos",
      "escape_game": "Escape room",
      "garden": "Jardí",
      "golf_course": "Camp de golf",
      "hackerspace": "Hackerspace",
      "ice_rink": "Pista de gel",
      "marina": "Marina",
      "miniature_golf": "Golf en miniatura",
      "park": "Parc",
      "pitch": "Camp",
      "playground": "Parc infantil",
      "sports_centre": "Centre esportiu",
      "stadium": "Estadi",
      "swimming_area": "Àrea de natació",
      "swimming_pool": "Piscina",
      "water_park": "Parc aqüàtic"
    },
    "office": {
      "government": "Govern"
    },
    "railway": {
      "halt": "Atura",
      "station": "Estació",
      "subway_entrance": "Entrada de metro",
      "train_station_entrance": "Entrada d'estació de tren",
      "tram_stop": "Parada de tram"
    },
    "shop": {
      "accessories": "Accessoris",
      "alcohol": "Alcohol",
      "antiques": "Antigüitats",
      "art": "Art",
      "bag": "Bossa",
      "bakery": "Fleca",
      "beauty": "Bellessa",
      "bed": "Llit",
      "beverages": "Begudes",
      "bicycle": "Bicicleta",
      "books": "Llibres",
      "boutique": "Boutique",
      "butcher": "Carnisser",
      "camera": "Càmara",
      "car": "Cotxe",
      "car_parts": "Recanvis de cotxe",
      "car_repair": "Taller de cotxes",
      "carpet": "Catifa",
      "charity": "Caritat",
      "cheese": "Formatge",
      "chemist": "Farmàcia",
      "chocolate": "Xocolata",
      "clothes": "Roba",
      "coffee": "Café",
      "computer": "Ordinador",
      "confectionery": "Llaminadures",
      "convenience": "Botiga de conveniència",
      "copyshop": "Copisteria",
      "cosmetics": "Cosmètics",
      "deli": "Deli",
      "delicatessen": "Delicatessen",
      "department_store": "Grans magatzems",
      "doityourself": "Fes-ho tu",
      "dry_cleaning": "Neteja en sec",
      "electronics": "Electrònica",
      "erotic": "Eròtic",
      "fabric": "Teixit",
      "fashion_accessories": "Complements de moda",
      "florist": "Florista",
      "frame": "Marc",
      "frozen_food": "Menjar congelat",
      "furniture": "Mobles",
      "garden_centre": "Centre de jardineria",
      "general": "General",
      "gift": "Regal",
      "greengrocer": "Fruiteria",
      "hairdresser": "Perruqueria",
      "hardware": "Ferreteria",
      "hearing_aids": "Audiòfons",
      "hifi": "Hifi",
      "houseware": "Parament per a la llar",
      "ice_cream": "Gelat",
      "interior_decoration": "Decoració d'interiors",
      "jewelry": "Joia",
      "kiosk": "Quiosc",
      "kitchen": "Cuina",
      "lamps": "Il·luminació",
      "laundry": "Bugada",
      "leather": "Cuir",
      "locksmith": "Serraller",
      "mall": "Centre comercial",
      "massage": "Massatge",
      "mobile_phone": "Telèfon mòbil",
      "motorcycle": "Motocicleta",
      "music": "Música",
      "musical_instrument": "Instruments musicals",
      "newsagent": "Quiosc de premsa",
      "optician": "Òptica",
      "outdoor": "Exterior",
      "pastry": "Pastisseria",
      "perfume": "Perfum",
      "perfumery": "Perfumeria",
      "pet": "Mascota",
      "photo": "Fotografia",
      "seafood": "Peix",
      "second_hand": "Segona mà",
      "shoes": "Sabates",
      "sports": "Esports",
      "stationery": "Papereria",
      "supermarket": "Supermercat",
      "tailor": "Modista",
      "tattoo": "Tatuatge",
      "tea": "Te",
      "ticket": "Bitllet",
      "tobacco": "Tabacs",
      "toys": "Joguines",
      "travel_agency": "Agència de viatges",
      "variety_store": "Basar",
      "video": "Video",
      "video_games": "Videojocs",
      "watches": "Rellotges",
      "weapons": "Armes",
      "wholesale": "Venda a l'engròs",
      "wine": "Vinoteca"
    },
    "sport": {
      "american_football": "Futbol americà",
      "archery": "Tir amb arc",
      "athletics": "Atletisme",
      "australian_football": "Futbol australià",
      "badminton": "Badminton",
      "baseball": "Beisbol",
      "basketball": "Bàsquet",
      "beachvolleyball": "Volei platja",
      "billiards": "Billar",
      "bmx": "Bmx",
      "boules": "Petanca",
      "bowls": "Bitlles",
      "boxing": "Boxa",
      "canadian_football": "Futbol canadenc",
      "canoe": "Canoa",
      "chess": "Escacs",
      "climbing": "Escalada",
      "climbing_adventure": "Escalada d'aventura",
      "cricket": "Cricket",
      "cricket_nets": "Xarxa de cricket",
      "croquet": "Croquet",
      "curling": "Curling",
      "cycling": "Ciclisme",
      "disc_golf": "Frisbee golf",
      "diving": "Busseig",
      "dog_racing": "Cursa de gossos",
      "equestrian": "Eqüestre",
      "fatsal": "Fatsal",
      "field_hockey": "Hoquei gespa",
      "free_flying": "Vol lliure",
      "gaelic_games": "Jocs gaèlics",
      "golf": "Golf",
      "gymnastics": "Gimnàstica",
      "handball": "Handbol",
      "hockey": "Hoquei",
      "horse_racing": "Curses de cavalls",
      "horseshoes": "Ferradures",
      "ice_hockey": "Hoquei gel",
      "ice_stock": "Curling bàvar",
      "judo": "Judo",
      "karting": "Karting",
      "korfball": "Korfball",
      "long_jump": "Salt de longitud",
      "model_aerodrome": "Model d'aeròdrom",
      "motocross": "Motocròs",
      "motor": "Motor",
      "multi": "Multi",
      "netball": "Netball",
      "orienteering": "Orientació",
      "paddle_tennis": "Pàdel",
      "paintball": "Paintball",
      "paragliding": "Parapent",
      "pelota": "Pilota",
      "racquet": "Raqueta",
      "rc_car": "Cotxes radiocontrol",
      "rowing": "Rem",
      "rugby": "Rugbi",
      "rugby_league": "Lliga de Tugby",
      "rugby_union": "Unió de Tugby",
      "running": "Còrrer",
      "sailing": "Vela",
      "scuba_diving": "Submarinisme",
      "shooting": "Tir",
      "shooting_range": "Camp de tir",
      "skateboard": "Monopatí",
      "skating": "Patinatge",
      "skiing": "Esquí",
      "soccer": "Futbol",
      "surfing": "Surf",
      "swimming": "Natació",
      "table_soccer": "Futbolí",
      "table_tennis": "Tenis taula",
      "team_handball": "Equip d'handbol",
      "tennis": "Tenis",
      "toboggan": "Tobogan",
      "volleyball": "Voleibol",
      "water_ski": "Esquí aquàtic",
      "yoga": "Ioga"
    },
    "tourism": {
      "alpine_hut": "Cabana alpina",
      "aquarium": "Aquari",
      "artwork": "Obra d'art",
      "attraction": "Atracció",
      "bed_and_breakfast": "Pensió",
      "camp_site": "Lloc d'acampada",
      "caravan_site": "Lloc de caravanes",
      "chalet": "Xalet",
      "gallery": "Galeria",
      "guest_house": "Casa de convidats",
      "hostel": "Alberg",
      "hotel": "Hotel",
      "information": "Informació",
      "motel": "Motel",
      "museum": "Museu",
      "picnic_site": "Lloc de pícnic",
      "theme_park": "Parc temàtic",
      "viewpoint": "Mirador",
      "zoo": "Zoològic"
    }
  },
  "search": "Cerca",
  "sidebar": {
    "about": {
      "created_by": "Creat per François de Metz",
      "title": "Sobre"
    },
    "api": {
      "embed": {
        "cta": "Llegeix el document",
        "subtitle": "Mostra el visor interactiu, la previsualització d'arxius i més mitjançant l'API remota.",
        "title": "Incrusta indoor="
      },
      "indoorequal": {
        "cta": "Resgistra",
        "subtitle": "Per començar, registra el teu compte gratuït a indoorequal.com.",
        "title": "Visualitza les tessel·les vectorials d'indoor="
      },
      "schema": {
        "cta": "Llegeix",
        "subtitle": "Descobreix l'esquema de tessel·les vectorials per tal d'integrar les tessel·les a qualsevol aplicació.",
        "title": "Llegeix l'esquema"
      },
      "subtitle": "Integra indoor= a la teva aplicació",
      "title": "Desenvolupadors"
    },
    "collapse": "Col·lapsa",
    "description": "indoor= mostra dades interiors de {0} i previsualitza GeoJSON, OSM i arxius IMDF.",
    "explore": {
      "subtitle": "Mostra la ubicació interior",
      "title": "Explora"
    },
    "last_update": "Última dada actualitzada {0}. {1}.",
    "status": "Estat",
    "learn": {
      "subtitle": "Afegeix una àrea interior a OpenStreetMap",
      "title": "Aprèn"
    },
    "news": {
      "subtitle": "Què hi ha de nou a indoor=",
      "title": "Noticies"
    },
    "preview": {
      "title": "Previsualitza la renderització",
      "subtitle": "Previsualitza el cartografiat interior abans d'actualitzar"
    },
    "taginfo": {
      "title": "Taginfo indoor=",
      "subtitle": "Instància dedicada a elements interiors"
    },
    "osm": "OpenStreetMap"
  },
  "preview": {
    "close": "Surt de la previsualització",
    "description": "Comprova el renderitzat de la cartografia d'interiors. Selecciona o arrastra-hi un GeoJSON o arxiu osm que segueixi l'esquema d'Etiquetatge Senzill d'Interiors.",
    "download": "Descarrega l'arxiu de previsualització",
    "error": "Error al mostrar la previsualització: {msg}.",
    "error_bad_file": "No s'ha pogut analitzar l'arxiu",
    "error_no_level": "No s'han detectat elements d'interiors",
    "file": "GeoJSON, osm o arxius IMDF (zip)",
    "how": {
      "content1": "Obre JOSM i edita una àrea interior",
      "content2": "Fes clic a \"Arxiu\" -> \"Guardar com\" i guarda l'arxiu com a GeoJSON o osm.",
      "content3": "Selecciona el fitxer al formulari següent",
      "title": "Com"
    },
    "imdf": "La previsualització també admet arxius IMDF.",
    "no_data_uploaded": "No s'han pujat dades de manera remota. Tot s'ha realitzat al teu dispositiu.",
    "title": "Previsualitza la renderització",
    "url": {
      "no": "No",
      "text": "Vols carregar un fitxer de previsualització de {0}?",
      "title": "Carrega previsualització externa",
      "yes": "Si"
    }
  },
  "toolbar": {
    "edit": "Edita OpenStreetMap",
    "menu": "Menú",
    "view": "Veure el mapa",
    "zoom_to_edit": "Apropa't per a editar"
  },
  "wheelchair": {
    "limited": "Accessibilitat limitada",
    "no": "No accessible",
    "yes": "Accessible amb cadira de rodes"
  },
  "zoom": "Apropa't per veure informació d'interiors"
}
