---
date: 2022-06-27
title: v0.3.1 of the rendering
---

**2 new POIs have been added**

- [amenity=ticket_validator](https://wiki.openstreetmap.org/wiki/Tag:amenity%3Dticket_validator)

- [amenity=photo_booth](https://wiki.openstreetmap.org/wiki/Tag:amenity%3Dphoto_booth)

**A bug that appeared while updating the data have been fixed.**
