---
date: 2022-08-21
title: v0.3.3 of the rendering
---

This is a bug fix release:

- Indoor polygons with dash in the `level` tag where not correctly imported in the database

- The `ref` tag on indoor polygons was no longer displayed if the name was missing

Thanks [HMT-HRO-MaMe](https://github.com/HMT-HRO-MaMe) for reporting these issues.
