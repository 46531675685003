{
  "days": {
    "friday": "Viernes",
    "monday": "Lunes",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "thursday": "Jueves",
    "tuesday": "Martes",
    "wednesday": "Miércoles"
  },
  "discover": {
    "explore": "Explora Localizaciones"
  },
  "explore_list": {
    "description": "Esta lista se ha extraído de {wikipage}.",
    "error": "Error mientras se cargaba la lista.",
    "subtitle": "{city} - {nation}",
    "title": "Explora",
    "wikipage": "Pàgina de la wiki de Etiquetaje Sencillo de Interiores"
  },
  "heatmap": {
    "hide": "Oculta el mapa de calor",
    "show": "Muestra el mapa de calor"
  },
  "opening_hours": {
    "state": {
      "closed": "cerrado",
      "open": "abierto"
    },
    "state_until_date": "{state} hasta el {date}",
    "tomorrow": "mañana",
    "url": "Visita la página web para consultar las horas de apertura"
  },
  "poi": {
    "aerialway": {
      "station": "Estación"
    },
    "aeroway": {
      "gate": "Puerta de Embarque"
    },
    "amenity": {
      "arts_centre": "Centro de artes",
      "atm": "Cajero automático",
      "bank": "Banco",
      "bar": "Bar",
      "bbq": "Barbacoa",
      "bench": "Banco",
      "bicycle_parking": "Aparcamiento de bicicletas",
      "bicycle_rental": "Alquiler de bicicletas",
      "biergarten": "Biergarten",
      "bureau_de_change": "Cambio de divisas",
      "bus_station": "Estación de autobuses",
      "cafe": "Cafeteria",
      "car_rental": "Alquiler de coches",
      "cinema": "Cine",
      "clinic": "Clínica",
      "college": "Universidad",
      "community_centre": "Centro comunitario",
      "courthouse": "Juzgado",
      "dentist": "Dentista",
      "doctors": "Médicos",
      "embassy": "Embajada",
      "fast_food": "Comida ràpida",
      "ferry_terminal": "Terminal de ferri",
      "fire_station": "Estación de bomberos",
      "food_court": "Patio de restauración",
      "fuel": "Combustible",
      "grave_yard": "Cementerio",
      "hospital": "Hospital",
      "ice_cream": "Helados",
      "kindergarten": "Guarderia",
      "library": "Biblioteca",
      "marketplace": "Mercado",
      "motorcycle_parking": "Aparcamiento de motocicletas",
      "nightclub": "Discoteca",
      "nursing_home": "Residencia de ancianos",
      "parking": "Aparcamiento",
      "pharmacy": "Farmacia",
      "photo_booth": "Cabina de fotos",
      "piano": "Piano",
      "place_of_worship": "Lugar de culto",
      "police": "Policia",
      "post_box": "Buzón de correos",
      "post_office": "Oficina de correos",
      "prison": "Prisión",
      "pub": "Bar",
      "public_building": "Edificio público",
      "recycling": "Reciclaje",
      "restaurant": "Restaurante",
      "school": "Escuela",
      "shelter": "Refugio",
      "swimming_pool": "Piscina",
      "taxi": "Taxi",
      "telephone": "Teléfono",
      "theatre": "Teatro",
      "ticket_validator": "Validadora de billetes",
      "toilets": "Lavabos",
      "townhall": "Ayuntamiento",
      "university": "Universidad",
      "vending_machine": "Máquina de vending",
      "veterinary": "Veterinario",
      "waste_basket": "Papelera"
    },
    "barrier": {
      "bollard": "Bolardo",
      "border_control": "Control de fronteras",
      "cycle_barrier": "Barrera ciclable",
      "gate": "Puerta",
      "lift_gate": "Puerta levadiza",
      "sally_port": "Entrada de seguridad",
      "stile": "Estilo",
      "toll_booth": "Cabina de peaje"
    },
    "craft": {
      "brewery": "Cervecería",
      "caterer": "Catering",
      "confectionery": "Dulces",
      "dressmaker": "Modista",
      "electronics_repair": "Reparación de electrónica",
      "shoemaker": "Zapatero",
      "tailor": "Sastre"
    },
    "door": {
      "door": "Puerta",
      "hinged": "Puerta",
      "no": "Puerta",
      "overhead": "Puerta",
      "sliding": "Puerta corredera",
      "yes": "Puerta"
    },
    "emergency": {
      "defibrillator": "Desfibrilador",
      "fire_extinguisher": "Extintor",
      "phone": "Teléfono de emergencia"
    },
    "entrance": {
      "building": "Entrada",
      "emergency": "Salida de emergencia",
      "exit": "Salida",
      "home": "Entrada",
      "main": "Entrada",
      "service": "Servicio",
      "shop": "Entrada",
      "staircase": "Entrada",
      "yes": "Entrada"
    },
    "exhibit": {
      "artwork": "Obra de arte",
      "history": "Historia"
    },
    "highway": {
      "elevator": "Ascensor"
    },
    "landuse": {
      "basin": "Cuenca",
      "brownfield": "Solar",
      "cemetery": "Cementerio",
      "reservoir": "Depósito",
      "winter_sports": "Deportes de invierno"
    },
    "leisure": {
      "dog_park": "Parque de perros",
      "escape_game": "Escape room",
      "garden": "Jardín",
      "golf_course": "Campo de golf",
      "hackerspace": "Hackerspace",
      "ice_rink": "Pista de hielo",
      "marina": "Marina",
      "miniature_golf": "Golf en miniatura",
      "park": "Parque",
      "pitch": "Campo",
      "playground": "Parque infantil",
      "sports_centre": "Centro de deportes",
      "stadium": "Estadio",
      "swimming_area": "Área de natación",
      "swimming_pool": "Piscina",
      "water_park": "Parque aquático"
    },
    "office": {
      "government": "Gobierno"
    },
    "railway": {
      "halt": "Parar",
      "station": "Estación",
      "subway_entrance": "Entrada de metro",
      "train_station_entrance": "Entrada de estación de tren",
      "tram_stop": "Parada de tranvía"
    },
    "shop": {
      "accessories": "Complementos",
      "alcohol": "Alcohol",
      "antiques": "Antigüedades",
      "art": "Arte",
      "bag": "Bolsa",
      "bakery": "Panadería",
      "beauty": "Belleza",
      "bed": "Cama",
      "beverages": "Bebidas",
      "bicycle": "Bicicleta",
      "books": "Libros",
      "boutique": "Boutique",
      "butcher": "Carnicero",
      "camera": "Cámara",
      "car": "Coche",
      "car_parts": "Recambios de coche",
      "car_repair": "Taller de coches",
      "carpet": "Alfombra",
      "charity": "Caridad",
      "cheese": "Queso",
      "chemist": "Farmacia",
      "chocolate": "Chocolate",
      "clothes": "Ropa",
      "coffee": "Café",
      "computer": "Ordenadores",
      "confectionery": "Dulces",
      "convenience": "Tienda de conveniencia",
      "copyshop": "Copisteria",
      "cosmetics": "Cosméticos",
      "deli": "Deli",
      "delicatessen": "Delicatessen",
      "department_store": "Grandes almacenes",
      "doityourself": "Hazlo tu mismo",
      "dry_cleaning": "Limpieza en seco",
      "electronics": "Electrónica",
      "erotic": "Erótico",
      "fabric": "Telas",
      "fashion_accessories": "Complementos de moda",
      "florist": "Floristeria",
      "frame": "Marcos",
      "frozen_food": "Congelados",
      "furniture": "Muebles",
      "garden_centre": "Centro de jardineria",
      "general": "General",
      "gift": "Regalos",
      "greengrocer": "Fruteria",
      "hairdresser": "Peluquería",
      "hardware": "Ferreteria",
      "hearing_aids": "Audífonos",
      "hifi": "Hifi",
      "houseware": "Artículos para el hogar",
      "ice_cream": "Helados",
      "interior_decoration": "Decoración de interiores",
      "jewelry": "Joieria",
      "kiosk": "Kiosco",
      "kitchen": "Cocinas",
      "lamps": "Iluminación",
      "laundry": "Lavandería",
      "leather": "Cuero",
      "locksmith": "Cerrajero",
      "mall": "Centro comercial",
      "massage": "Masajes",
      "mobile_phone": "Teléfono móvil",
      "motorcycle": "Motocicleta",
      "music": "Música",
      "musical_instrument": "Instrumentos musicales",
      "newsagent": "Kiosco de periódicos",
      "optician": "Óptica",
      "outdoor": "Exterior",
      "pastry": "Pastelería",
      "perfume": "Perfume",
      "perfumery": "Perfumería",
      "pet": "Mascotas",
      "photo": "Fotografía",
      "seafood": "Pescadería",
      "second_hand": "Segunda mano",
      "shoes": "Zapatería",
      "sports": "Deportes",
      "stationery": "Papelería",
      "supermarket": "Supermercado",
      "tailor": "Sastre",
      "tattoo": "Tatuajes",
      "tea": "Te",
      "ticket": "Tickets",
      "tobacco": "Tabacos",
      "toys": "Juguetes",
      "travel_agency": "Agencia de viajes",
      "variety_store": "Bazar",
      "video": "Video",
      "video_games": "Videojuegos",
      "watches": "Relojes",
      "weapons": "Armas",
      "wholesale": "Al por mayor",
      "wine": "Vinoteca"
    },
    "sport": {
      "american_football": "Fútbol americano",
      "archery": "Tiro con arco",
      "athletics": "Atletismo",
      "australian_football": "Fútbol australiano",
      "badminton": "Badminton",
      "baseball": "Beisbol",
      "basketball": "Baloncesto",
      "beachvolleyball": "Volei playa",
      "billiards": "Billar",
      "bmx": "Bmx",
      "boules": "Petanca",
      "bowls": "Bolos",
      "boxing": "Boxeo",
      "canadian_football": "Fútbol canadiense",
      "canoe": "Canoa",
      "chess": "Ajedrez",
      "climbing": "Escalada",
      "climbing_adventure": "Escalada de aventura",
      "cricket": "Cricket",
      "cricket_nets": "Redes de cricket",
      "croquet": "Croquet",
      "curling": "Curling",
      "cycling": "Ciclismo",
      "disc_golf": "Frisbee golf",
      "diving": "Buceo",
      "dog_racing": "Carreras de perros",
      "equestrian": "Ecuestre",
      "fatsal": "Fatsal",
      "field_hockey": "Hockey hierba",
      "free_flying": "Vuelo libre",
      "gaelic_games": "Juegos gaélicos",
      "golf": "Golf",
      "gymnastics": "Gimnasia",
      "handball": "Balonmano",
      "hockey": "Hockey",
      "horse_racing": "Carrera de caballos",
      "horseshoes": "Herraduras",
      "ice_hockey": "Hockey hielo",
      "ice_stock": "Curling bávaro",
      "judo": "Judo",
      "karting": "Karting",
      "korfball": "Korfball",
      "long_jump": "Salto de longitud",
      "model_aerodrome": "Modelo de aeródromo",
      "motocross": "Motocross",
      "motor": "Motor",
      "multi": "Multi",
      "netball": "Balonred",
      "orienteering": "Orientación",
      "paddle_tennis": "Padel",
      "paintball": "Paintball",
      "paragliding": "Parapente",
      "pelota": "Pelota",
      "racquet": "Raqueta",
      "rc_car": "Coches radiocontrol",
      "rowing": "Remo",
      "rugby": "Rugby",
      "rugby_league": "Liga tugby",
      "rugby_union": "Unión tugby",
      "running": "Correr",
      "sailing": "Navegar",
      "scuba_diving": "Submarinismo",
      "shooting": "Tiro",
      "shooting_range": "Campo de tiro",
      "skateboard": "Monopatín",
      "skating": "Patinaje",
      "skiing": "Esquí",
      "soccer": "Fútbol",
      "surfing": "Surf",
      "swimming": "Natación",
      "table_soccer": "Futbolín",
      "table_tennis": "Ping-pong",
      "team_handball": "Equipo de balonmano",
      "tennis": "Tenis",
      "toboggan": "Tobogán",
      "volleyball": "Volei",
      "water_ski": "Esquí acuático",
      "yoga": "Yoga"
    },
    "tourism": {
      "alpine_hut": "Cabaña alpina",
      "aquarium": "Acuario",
      "artwork": "Obra de arte",
      "attraction": "Atracción",
      "bed_and_breakfast": "Pensión",
      "camp_site": "Lugar de acampada",
      "caravan_site": "Lugar de caravanas",
      "chalet": "Chalet",
      "gallery": "Galeria",
      "guest_house": "Casa de invitados",
      "hostel": "Albergue",
      "hotel": "Hotel",
      "information": "Información",
      "motel": "Motel",
      "museum": "Museo",
      "picnic_site": "Lugar de picnic",
      "theme_park": "Parque temático",
      "viewpoint": "Mirador",
      "zoo": "Zoo"
    }
  },
  "search": "Buscar",
  "sidebar": {
    "about": {
      "created_by": "Creado por François de Metz",
      "title": "Sobre"
    },
    "api": {
      "embed": {
        "cta": "Lee la documentación",
        "subtitle": "Muestra el visor interactivo, previsualiza archivos y más con la API remota.",
        "title": "Incrustar indoor="
      },
      "indoorequal": {
        "cta": "Registra",
        "subtitle": "Para empezar, registra tu cuenta gratuita en indoorequal.com.",
        "title": "Mostrar las teselas vectoriales de indoor="
      },
      "schema": {
        "cta": "Lee",
        "subtitle": "Descubre el esquema de teselas vectoriales para integrar las teselas en cualquier aplicación.",
        "title": "Lee el esquema"
      },
      "subtitle": "Integra indoor= a tu aplicación",
      "title": "Desarrolladores"
    },
    "collapse": "Colapsa",
    "description": "indoor= muestra datos de {0} y previsualiza archivos GeoJSON, OSM y IMDF.",
    "explore": {
      "subtitle": "Indicar la localización en interiores",
      "title": "Explora"
    },
    "last_update": "Último dato actualizado {0}. {1}.",
    "status": "Estado",
    "learn": {
      "subtitle": "Mapea una área interior en OpenStreetMap",
      "title": "Aprende"
    },
    "news": {
      "subtitle": "Qué hay de nuevo en indoor=",
      "title": "Noticias"
    },
    "preview": {
      "title": "Previsualizar renderizado",
      "subtitle": "Previsualizar mapeo de interiores antes de subirlo"
    },
    "taginfo": {
      "title": "Taginfo indoor=",
      "subtitle": "Instancia dedicada a elementos de interiores"
    },
    "osm": "OpenStreetMap"
  },
  "preview": {
    "close": "Salir de la previsualización",
    "description": "Comprueba el renderizado de tu mapeo de interiores. Selecciona o arrastra GeoJSON o archivos osm que sigan el esquema de Etiquetado Sencillo de Interiores.",
    "download": "Descarga el archivo de previsualización",
    "error": "Error mientras se mostraba la previsualización: {msg}.",
    "error_bad_file": "No se puede leer el archivo",
    "error_no_level": "No se han detectado elementos interiores",
    "file": "Archivos GeoJSON, osm o IMDF (zip)",
    "how": {
      "content1": "Abre JOSM y edita una àrea interior",
      "content2": "Haz clic en \"Archivo\" -> \"Guardar como\" y guarda el archivo GeoJSON o osm.",
      "content3": "Selecciona el archivo en el siguiente formulario",
      "title": "Como"
    },
    "imdf": "La previsualización también admite archivos IMDF.",
    "no_data_uploaded": "No se ha cargado ningún dato de manera remota. Todo ha ocurrido en tu dispositivo.",
    "title": "Previsualizar renderizado",
    "url": {
      "no": "No",
      "text": "Quieres cargar una previsualización del fichero de {0}?",
      "title": "Cargar previsualización externa",
      "yes": "Sí"
    }
  },
  "toolbar": {
    "edit": "Edita OpenStreetMap",
    "menu": "Menú",
    "view": "Ver mapa",
    "zoom_to_edit": "Haz zoom para editar"
  },
  "wheelchair": {
    "limited": "Accesibilidad limitada",
    "no": "No accesible",
    "yes": "Accesible con silla de ruedas"
  },
  "zoom": "Haz zoom para ver información de interiores"
}
