{
  "days": {
    "friday": "Friday",
    "monday": "Monday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "thursday": "Thursday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday"
  },
  "discover": {
    "explore": "Explore Locations"
  },
  "explore_list": {
    "description": "This list is extracted from the {wikipage}.",
    "error": "Error while loading the list.",
    "subtitle": "{city} - {nation}",
    "title": "Explore",
    "wikipage": "Simple Indoor Tagging wiki page"
  },
  "heatmap": {
    "hide": "Hide the heatmap",
    "show": "Show the heatmap"
  },
  "opening_hours": {
    "state": {
      "closed": "closed",
      "open": "open"
    },
    "state_until_date": "{state} until {date}",
    "tomorrow": "tomorrow",
    "url": "Visit the website to consult the opening hours"
  },
  "poi": {
    "aerialway": {
      "station": "Station"
    },
    "aeroway": {
      "gate": "Airport Gate"
    },
    "amenity": {
      "arts_centre": "Arts centre",
      "atm": "ATM",
      "bank": "Bank",
      "bar": "Bar",
      "bbq": "BBQ",
      "bench": "Bench",
      "bicycle_parking": "Bicycle parking",
      "bicycle_rental": "Bicycle rental",
      "biergarten": "Biergarten",
      "bureau_de_change": "Bureau de change",
      "bus_station": "Bus station",
      "cafe": "Cafe",
      "car_rental": "Car rental",
      "cinema": "Cinema",
      "clinic": "Clinic",
      "college": "College",
      "community_centre": "Community centre",
      "courthouse": "Courthouse",
      "dentist": "Dentist",
      "doctors": "Doctors",
      "embassy": "Embassy",
      "fast_food": "Fast food",
      "ferry_terminal": "Ferry terminal",
      "fire_station": "Fire station",
      "food_court": "Food court",
      "fuel": "Fuel",
      "grave_yard": "Grave yard",
      "hospital": "Hospital",
      "ice_cream": "Ice cream",
      "kindergarten": "Kindergarten",
      "library": "Library",
      "marketplace": "Marketplace",
      "motorcycle_parking": "Motorcycle parking",
      "nightclub": "Nightclub",
      "nursing_home": "Nursing home",
      "parking": "Parking",
      "pharmacy": "Pharmacy",
      "photo_booth": "Photo booth",
      "piano": "Piano",
      "place_of_worship": "Place of worship",
      "police": "Police",
      "post_box": "Post box",
      "post_office": "Post office",
      "prison": "Prison",
      "pub": "Pub",
      "public_building": "Public building",
      "recycling": "Recycling",
      "restaurant": "Restaurant",
      "school": "School",
      "shelter": "Shelter",
      "swimming_pool": "Swimming pool",
      "taxi": "Taxi",
      "telephone": "Telephone",
      "theatre": "Theatre",
      "ticket_validator": "Ticket validator",
      "toilets": "Toilets",
      "townhall": "Townhall",
      "university": "University",
      "vending_machine": "Vending machine",
      "veterinary": "Veterinary",
      "waste_basket": "Waste basket"
    },
    "barrier": {
      "bollard": "Bollard",
      "border_control": "Border control",
      "cycle_barrier": "Cycle barrier",
      "gate": "Gate",
      "lift_gate": "Lift gate",
      "sally_port": "Sally port",
      "stile": "Stile",
      "toll_booth": "Toll booth"
    },
    "craft": {
      "brewery": "Brewery",
      "caterer": "Caterer",
      "confectionery": "Confectionery",
      "dressmaker": "Dressmaker",
      "electronics_repair": "Electronics repair",
      "shoemaker": "Shoemaker",
      "tailor": "Tailor"
    },
    "door": {
      "door": "Door",
      "hinged": "Door",
      "no": "Door",
      "overhead": "Door",
      "sliding": "Sliding door",
      "yes": "Door"
    },
    "emergency": {
      "defibrillator": "Defibrillator",
      "fire_extinguisher": "Fire extinguisher",
      "phone": "Emergency phone"
    },
    "entrance": {
      "building": "Entrance",
      "emergency": "Emergency exit",
      "exit": "Exit",
      "home": "Entrance",
      "main": "Entrance",
      "service": "Service",
      "shop": "Entrance",
      "staircase": "Entrance",
      "yes": "Entrance"
    },
    "exhibit": {
      "artwork": "Artwork",
      "history": "History"
    },
    "highway": {
      "elevator": "Elevator"
    },
    "landuse": {
      "basin": "Basin",
      "brownfield": "Brownfield",
      "cemetery": "Cemetery",
      "reservoir": "Reservoir",
      "winter_sports": "Winter sports"
    },
    "leisure": {
      "dog_park": "Dog park",
      "escape_game": "Escape game",
      "garden": "Garden",
      "golf_course": "Golf course",
      "hackerspace": "Hackerspace",
      "ice_rink": "Ice rink",
      "marina": "Marina",
      "miniature_golf": "Miniature golf",
      "park": "Park",
      "pitch": "Pitch",
      "playground": "Playground",
      "sports_centre": "Sports centre",
      "stadium": "Stadium",
      "swimming_area": "Swimming area",
      "swimming_pool": "Swimming pool",
      "water_park": "Water park"
    },
    "office": {
      "government": "Government"
    },
    "railway": {
      "halt": "Halt",
      "station": "Station",
      "subway_entrance": "Subway entrance",
      "train_station_entrance": "Train station entrance",
      "tram_stop": "Tram stop"
    },
    "shop": {
      "accessories": "Accessories",
      "alcohol": "Alcohol",
      "antiques": "Antiques",
      "art": "Art",
      "bag": "Bag",
      "bakery": "Bakery",
      "beauty": "Beauty",
      "bed": "Bed",
      "beverages": "Beverages",
      "bicycle": "Bicycle",
      "books": "Books",
      "boutique": "Boutique",
      "butcher": "Butcher",
      "camera": "Camera",
      "car": "Car",
      "car_parts": "Car parts",
      "car_repair": "Car repair",
      "carpet": "Carpet",
      "charity": "Charity",
      "cheese": "Cheese",
      "chemist": "Chemist",
      "chocolate": "Chocolate",
      "clothes": "Clothes",
      "coffee": "Coffee",
      "computer": "Computer",
      "confectionery": "Confectionery",
      "convenience": "Convenience",
      "copyshop": "Copyshop",
      "cosmetics": "Cosmetics",
      "deli": "Deli",
      "delicatessen": "Delicatessen",
      "department_store": "Department store",
      "doityourself": "Doityourself",
      "dry_cleaning": "Dry cleaning",
      "electronics": "Electronics",
      "erotic": "Erotic",
      "fabric": "Fabric",
      "fashion_accessories": "Fashion accessories",
      "florist": "Florist",
      "frame": "Frame",
      "frozen_food": "Frozen food",
      "furniture": "Furniture",
      "garden_centre": "Garden centre",
      "general": "General",
      "gift": "Gift",
      "greengrocer": "Greengrocer",
      "hairdresser": "Hairdresser",
      "hardware": "Hardware",
      "hearing_aids": "Hearing aids",
      "hifi": "Hifi",
      "houseware": "Houseware",
      "ice_cream": "Ice cream",
      "interior_decoration": "Interior decoration",
      "jewelry": "Jewelry",
      "kiosk": "Kiosk",
      "kitchen": "Kitchen",
      "lamps": "Lamps",
      "laundry": "Laundry",
      "leather": "Leather",
      "locksmith": "Locksmith",
      "mall": "Mall",
      "massage": "Massage",
      "mobile_phone": "Mobile phone",
      "motorcycle": "Motorcycle",
      "music": "Music",
      "musical_instrument": "Musical instrument",
      "newsagent": "Newsagent",
      "optician": "Optician",
      "outdoor": "Outdoor",
      "pastry": "Pastry",
      "perfume": "Perfume",
      "perfumery": "Perfumery",
      "pet": "Pet",
      "photo": "Photo",
      "seafood": "Seafood",
      "second_hand": "Second hand",
      "shoes": "Shoes",
      "sports": "Sports",
      "stationery": "Stationery",
      "supermarket": "Supermarket",
      "tailor": "Tailor",
      "tattoo": "Tattoo",
      "tea": "Tea",
      "ticket": "Ticket",
      "tobacco": "Tobacco",
      "toys": "Toys",
      "travel_agency": "Travel agency",
      "variety_store": "Variety store",
      "video": "Video",
      "video_games": "Video games",
      "watches": "Watches",
      "weapons": "Weapons",
      "wholesale": "Wholesale",
      "wine": "Wine"
    },
    "sport": {
      "american_football": "American football",
      "archery": "Archery",
      "athletics": "Athletics",
      "australian_football": "Australian football",
      "badminton": "Badminton",
      "baseball": "Baseball",
      "basketball": "Basketball",
      "beachvolleyball": "Beachvolleyball",
      "billiards": "Billiards",
      "bmx": "Bmx",
      "boules": "Boules",
      "bowls": "Bowls",
      "boxing": "Boxing",
      "canadian_football": "Canadian football",
      "canoe": "Canoe",
      "chess": "Chess",
      "climbing": "Climbing",
      "climbing_adventure": "Climbing adventure",
      "cricket": "Cricket",
      "cricket_nets": "Cricket nets",
      "croquet": "Croquet",
      "curling": "Curling",
      "cycling": "Cycling",
      "disc_golf": "Disc golf",
      "diving": "Diving",
      "dog_racing": "Dog racing",
      "equestrian": "Equestrian",
      "fatsal": "Fatsal",
      "field_hockey": "Field hockey",
      "free_flying": "Free flying",
      "gaelic_games": "Gaelic games",
      "golf": "Golf",
      "gymnastics": "Gymnastics",
      "handball": "Handball",
      "hockey": "Hockey",
      "horse_racing": "Horse racing",
      "horseshoes": "Horseshoes",
      "ice_hockey": "Ice hockey",
      "ice_stock": "Ice stock",
      "judo": "Judo",
      "karting": "Karting",
      "korfball": "Korfball",
      "long_jump": "Long jump",
      "model_aerodrome": "Model aerodrome",
      "motocross": "Motocross",
      "motor": "Motor",
      "multi": "Multi",
      "netball": "Netball",
      "orienteering": "Orienteering",
      "paddle_tennis": "Paddle tennis",
      "paintball": "Paintball",
      "paragliding": "Paragliding",
      "pelota": "Pelota",
      "racquet": "Racquet",
      "rc_car": "Rc car",
      "rowing": "Rowing",
      "rugby": "Rugby",
      "rugby_league": "Tugby league",
      "rugby_union": "Tugby union",
      "running": "Running",
      "sailing": "Sailing",
      "scuba_diving": "Scuba diving",
      "shooting": "Shooting",
      "shooting_range": "Shooting range",
      "skateboard": "Skateboard",
      "skating": "Skating",
      "skiing": "Skiing",
      "soccer": "Soccer",
      "surfing": "Surfing",
      "swimming": "Swimming",
      "table_soccer": "Table soccer",
      "table_tennis": "Table tennis",
      "team_handball": "Team handball",
      "tennis": "Tennis",
      "toboggan": "Toboggan",
      "volleyball": "Volleyball",
      "water_ski": "Water ski",
      "yoga": "Yoga"
    },
    "tourism": {
      "alpine_hut": "Alpine hut",
      "aquarium": "Aquarium",
      "artwork": "Artwork",
      "attraction": "Attraction",
      "bed_and_breakfast": "Bed and breakfast",
      "camp_site": "Camp site",
      "caravan_site": "Caravan site",
      "chalet": "Chalet",
      "gallery": "Gallery",
      "guest_house": "Guest house",
      "hostel": "Hostel",
      "hotel": "Hotel",
      "information": "Information",
      "motel": "Motel",
      "museum": "Museum",
      "picnic_site": "Picnic site",
      "theme_park": "Theme park",
      "viewpoint": "Viewpoint",
      "zoo": "Zoo"
    }
  },
  "search": "Search",
  "sidebar": {
    "about": {
      "created_by": "Created by François de Metz",
      "title": "About"
    },
    "api": {
      "embed": {
        "cta": "Read the doc",
        "subtitle": "Display the interactive viewer, preview files and more with the remote API.",
        "title": "Embed indoor="
      },
      "indoorequal": {
        "cta": "Register",
        "subtitle": "To start, register your free account on indoorequal.com.",
        "title": "Display indoor= vector tiles"
      },
      "schema": {
        "cta": "Read",
        "subtitle": "Discover the vector tile schema to integrate the tiles in any app.",
        "title": "Read the schema"
      },
      "subtitle": "Integrate indoor= to your app",
      "title": "Developers"
    },
    "collapse": "Collapse",
    "description": "indoor= display indoor data from {0} and preview GeoJSON, OSM and IMDF files.",
    "explore": {
      "subtitle": "Showcase indoor location",
      "title": "Explore"
    },
    "last_update": "Last data update {0}. {1}.",
    "status": "Status",
    "learn": {
      "subtitle": "Map indoor area in OpenStreetMap",
      "title": "Learn"
    },
    "news": {
      "subtitle": "What's new on indoor=",
      "title": "News"
    },
    "preview": {
      "title": "Preview rendering",
      "subtitle": "Preview indoor mapping before upload"
    },
    "taginfo": {
      "title": "Taginfo indoor=",
      "subtitle": "Instance dedicated to indoor features"
    },
    "osm": "OpenStreetMap"
  },
  "preview": {
    "close": "Exit preview",
    "description": "Check the rendering of your indoor mapping. Select or drag and drop a GeoJSON or osm file that follow the Simple Indoor Tagging scheme.",
    "download": "Download the preview file",
    "error": "Error while displaying the preview: {msg}.",
    "error_bad_file": "Could not parse the file",
    "error_no_level": "No indoor features detected",
    "file": "GeoJSON, osm or IMDF (zip) file",
    "how": {
      "content1": "Open JOSM and edit an indoor area",
      "content2": "Click on \"File\" -> \"Save as\" and save the file as GeoJSON or osm.",
      "content3": "Select the file in the form below",
      "title": "How to"
    },
    "imdf": "The preview also support IMDF files.",
    "no_data_uploaded": "No data are uploaded remotely. Everything happens in your computer.",
    "title": "Preview rendering",
    "url": {
      "no": "No",
      "text": "Do you want to load a preview file from {0}?",
      "title": "Load external preview",
      "yes": "Yes"
    }
  },
  "toolbar": {
    "edit": "Edit OpenStreetMap",
    "menu": "Menu",
    "view": "View map",
    "zoom_to_edit": "Zoom in to edit"
  },
  "wheelchair": {
    "limited": "Limited accessibility",
    "no": "Not accessible",
    "yes": "Wheelchair accessible"
  },
  "zoom": "Zoom in to see indoor data"
}
